<template>
  <div ref="chat" class="chat"></div>
</template>

<style lang="less" scoped>
.chat {
  height: 100%;
}
</style>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chatInstance: null,
      defaultOptions: {
        color: ['#35CEFC', '#F9D738', '#FFAB8A', '#FF518A', '#8FFCC3', '#00B585'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          icon: 'circle',
          bottom: '0',
          left: 'center',
          itemWight: 16,
          itemHeight: 10,
          itemGap: 16,
          padding: [0, 0, 10, 0],
          textStyle: {
            color: '#A6C3F6'
          }
        },
        series: [
          {
            name: '零工分类',
            type: 'pie',
            center: ['50%', '40%'],
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            }
          }
        ]
      }
    };
  },
  computed: {
    chatData() {
      return this.data.map((item) => ({ value: item.count, name: item.name }));
    },
    chatOptions() {
      const self = this;
      return {
        ...self.defaultOptions,
        series: [{ ...self.defaultOptions.series[0], data: self.chatData }]
      };
    }
  },
  methods: {
    initChat() {
      this.chatInstance = this.$echarts.init(this.$refs.chat);
      this.chatInstance.setOption(this.chatOptions);
    }
  },
  mounted() {
    this.initChat();
  }
};
</script>
