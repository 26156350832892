<template>
  <div class="odd-big-screen">
    <FitScreen v-if="!loading" :width="1920" :height="1080" mode="fit">
      <div class="app-container">
        <div class="app-header">
          <div class="title gradient-text">{{dataView.siteName || '零工数据大屏'}}</div>
          <div class="date"><SystemTime /></div>
        </div>
        <div class="app-content">
          <div class="digital-board-wrap">
            <div class="digital-board" v-for="item in digitalBoards" :key="item.title">
              <div class="icon"></div>
              <div class="info">
                <div class="number gradient-number">
                  <CountTo :end-val="item.number" separator="" :duration="3000"></CountTo>
                </div>
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="chat-board-wrap">
            <div class="chat-board chat-board--small">
              <div class="title gradient-text">零工分类</div>
              <div class="content">
                <ChatOddCate :data="dataView.infoSubareas" />
              </div>
            </div>
            <div class="chat-board chat-board--default">
              <div class="title gradient-text">新增零工趋势 (近一年)</div>
              <div class="content">
                <ChatOddTrend :data="dataView.oddJobsTrend" />
              </div>
            </div>
            <div class="chat-board chat-board--small">
              <div class="title gradient-text">零工区域</div>
              <div class="content">
                <div class="chat">
                  <ChatOddArea :data="dataView.oddJobsRegion" />
                </div>
              </div>
            </div>
            <div class="chat-board chat-board--medium">
              <div class="title gradient-text">新增用户趋势 (近半年)</div>
              <div class="content">
                <div class="chat">
                  <ChatDialTrend :data="dataView.oddUsersTrend" />
                </div>
              </div>
            </div>
            <div class="chat-board chat-board--default">
              <div class="title gradient-text">新增服务趋势 (近一年)</div>
              <div class="content">
                <div class="chat">
                  <ChatServiceTrend :data="dataView.oddJobsServiceTrend" />
                </div>
              </div>
            </div>
            <div class="chat-board chat-board--small">
              <div class="title gradient-text">服务区域</div>
              <div class="content">
                <div class="chat">
                  <ChatServiceArea :data="dataView.oddServiceRegion" />
                </div>
              </div>
            </div>
            <div class="chat-board chat-board--small">
              <div class="title gradient-text">服务分类</div>
              <div class="content">
                <div class="chat">
                  <ChatServiceCate :data="dataView.oddServiceSubareas" />
                </div>
              </div>
            </div>
            <div class="chat-board chat-board--medium">
              <div class="title gradient-text">拨号区域</div>
              <div class="content">
                <div class="chat">
                  <ChatDialArea :data="dataView.dialRegionCount" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FitScreen>
  </div>
</template>

<script>
import FitScreen from '@fit-screen/vue';
import CountTo from 'vue-count-to';
import SystemTime from '@/components/SystemTime.vue';
import ChatOddCate from './components/ChatOddCate.vue'; // 零工分类
import ChatOddTrend from './components/ChatOddTrend.vue'; // 新增零工趋势
import ChatOddArea from './components/ChatOddArea.vue'; // 零工区域
import ChatDialTrend from './components/ChatDialTrend.vue'; // 拨号趋势
import ChatServiceTrend from './components/ChatServiceTrend.vue'; // 新增服务趋势
import ChatServiceArea from './components/ChatServiceArea.vue'; // 服务区域
import ChatServiceCate from './components/ChatServiceCate.vue'; // 服务分类
import ChatDialArea from './components/ChatDialArea.vue'; // 拨号区域
export default {
  components: {
    FitScreen,
    CountTo,
    SystemTime,
    ChatOddCate,
    ChatOddTrend,
    ChatOddArea,
    ChatDialTrend,
    ChatServiceTrend,
    ChatServiceArea,
    ChatServiceCate,
    ChatDialArea
  },
  data() {
    return {
      timer: null,
      loading: true,
      dataView: {}
    };
  },
  computed: {
    digitalBoards() {
      return [
        {
          title: '服务项目数',
          number: this.dataView.serviceNum || 0
        },
        {
          title: '零工项目数',
          number: this.dataView.infoNum || 0
        },
        {
          title: '个人用户数',
          number: this.dataView.userNum || 0
        },
        {
          title: '企业用户数',
          number: this.dataView.companyNum || 0
        },
        {
          title: '拨号数',
          number: this.dataView.dialNum || 0
        }
      ];
    }
  },
  created() {
    this.getDataView();
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    clearTimer() {
      this.timer && clearTimeout(this.timer);
      this.timer = null;
    },

    async getDataView() {
      try {
        const res = await this.$api.odd.getDataview();

        this.clearTimer();

        this.dataView = res;

        const timer = setTimeout(this.getDataView.bind(this), 1e4);
        this.timer = timer;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: 'MiSans';
  src: url('/fonts/MiSans/MiSans-Normal.woff2') format('woff2'), url('/fonts/MiSans/MiSans-Normal.woff') format('woff');
}

.gradient-text {
  background-image: linear-gradient(to bottom, #eefbff, #73ffed);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-number {
  font-family: MiSans;
  background-image: linear-gradient(to bottom, #00cbff, #8ffff1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.odd-big-screen {
  width: 100vw;
  height: 100vh;
  background: #000 url(../../assets/imgs/odd/bg.jpg) no-repeat center;
  background-size: cover;
  .app-container {
    width: 100%;
    height: 100%;
    .app-header {
      position: relative;
      height: 100px;
      background: url(../../assets/imgs/odd/header.png) no-repeat center;
      background-size: 100%;
      padding-top: 20px;

      .title {
        font-weight: 600;
        font-size: 42px;
        color: #77e4ff;
        text-align: center;
      }
      .date {
        position: absolute;
        right: 40px;
        top: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #0056ea;
      }
    }
    .app-content {
      padding: 30px 30px 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .digital-board-wrap {
        display: flex;
        justify-content: space-between;
        .digital-board {
          width: 356px;
          height: 150px;
          background: url(../../assets/imgs/odd/panel_mini.png) no-repeat center;
          background-size: 100%;
          display: flex;
          align-items: center;
          gap: 24px;
          padding: 27px 30px;
          .icon {
            width: 96px;
            height: 96px;
          }
          .info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .number {
              font-weight: 600;
              font-size: 42px;
              color: #77e4ff;
            }
            .title {
              font-weight: 500;
              font-size: 16px;
              color: #6cb6ff;
            }
          }
          &:nth-child(1) {
            .icon {
              background: url(../../assets/imgs/odd/icon_service.png) no-repeat center;
              background-size: 100%;
            }
          }
          &:nth-child(2) {
            .icon {
              background: url(../../assets/imgs/odd/icon_project.png) no-repeat center;
              background-size: 100%;
            }
          }
          &:nth-child(3) {
            .icon {
              background: url(../../assets/imgs/odd/icon_user.png) no-repeat center;
              background-size: 100%;
            }
          }
          &:nth-child(4) {
            .icon {
              background: url(../../assets/imgs/odd/icon_company.png) no-repeat center;
              background-size: 100%;
            }
          }
          &:nth-child(5) {
            .icon {
              background: url(../../assets/imgs/odd/icon_dial.png) no-repeat center;
              background-size: 100%;
            }
          }
        }
      }
      .chat-board-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .chat-board {
          height: 367px;
          &--small {
            width: 380px;
            background: url(../../assets/imgs/odd/panel_small.png) no-repeat center;
            background-size: 100%;
          }
          &--medium {
            width: 450px;
            background: url(../../assets/imgs/odd/panel_medium.png) no-repeat center;
            background-size: 100%;
          }
          &--default {
            width: 590px;
            background: url(../../assets/imgs/odd/panel_default.png) no-repeat center;
            background-size: 100%;
          }
          .title {
            padding: 10px 20px;
            font-weight: 600;
            font-size: 18px;
            color: #77e4ff;
          }
          .content {
            height: 322px;
            padding: 15px;
            .chat {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
