<template>
  <div class="system-time">
    <span>{{ currentDate }} {{ currentTime }}</span>
    <span class="lunar">{{ lunarDate }}</span>
  </div>
</template>

<script>
import { Lunar } from 'lunar-javascript';

export default {
  name: 'SystemTime',
  data() {
    return {
      currentDate: '',
      currentTime: '',
      lunarDate: '',
      timer: null
    };
  },
  created() {
    this.updateTime();
    this.timer = setInterval(this.updateTime, 1000);

    // 获取农历日期
    const lunarData = Lunar.fromDate(new Date());
    this.lunarDate = `农历 ${lunarData.getMonthInChinese()}月${lunarData.getDayInChinese()}`;
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    updateTime() {
      const now = new Date();
      // 获取年月日
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      // 获取时分秒
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      this.currentDate = `${year}-${month}-${day}`;
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    }
  }
};
</script>

<style lang="less" scoped>
.lunar {
  margin-left: 24px;
}
</style>
