<template>
  <div ref="chat" class="chat"></div>
</template>

<style lang="less" scoped>
.chat {
  height: 100%;
}
</style>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chatInstance: null,
      defaultOptions: {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          axisLabel: {
            interval: 0,
            formatter: function (value) {
              return value.split('').join('\n');
            },
            color: '#A6C3F6'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          z: 10
        },
        yAxis: {
          show: false
        },
        series: [
          {
            type: 'bar',
            showBackground: false,
            barWidth: 12,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#0AE9FF' },
                { offset: 1, color: '#848EFF' }
              ]),
              borderRadius: [7, 7, 0, 0]
            },
            label: {
              show: true, // 开启显示
              position: 'top', // 在上方显示
              color: '#0EE6FF',
              fontSize: 12
            }
          }
        ]
      }
    };
  },
  computed: {
    chatData() {
      return this.data.map((item) => item.count);
    },
    xAxisData() {
      return this.data.map((item) => item.name);
    },
    chatOptions() {
      const self = this;
      return {
        ...self.defaultOptions,
        xAxis: { ...self.defaultOptions.xAxis, data: self.xAxisData },
        series: [{ ...self.defaultOptions.series[0], data: self.chatData }]
      };
    }
  },
  methods: {
    initChat() {
      this.chatInstance = this.$echarts.init(this.$refs.chat);
      this.chatInstance.setOption(this.chatOptions);
    }
  },
  mounted() {
    this.initChat();
  }
};
</script>
