<template>
  <div ref="chat" class="chat"></div>
</template>

<style lang="less" scoped>
.chat {
  height: 100%;
}
</style>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chatInstance: null,
      defaultOptions: {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            show: true,
            color: '#A6C3F6', // 更改坐标轴文字颜色
            fontSize: 12 // 更改坐标轴文字大小
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#00FC96'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            color: '#A6C3F6', // 更改坐标轴文字颜色
            fontSize: 12 // 更改坐标轴文字大小
          },
          splitLine: {
            show: false
          }
        },

        series: [
          {
            type: 'line',
            smooth: 0.6,
            symbol: 'circle',
            symbolSize: 8,
            lineStyle: {
              color: '#00FC96',
              width: 2
            },
            itemStyle: {
              borderWidth: 2,
              borderColor: '#010F7D',
              color: '#00FC96'
            }, // 线条样式
            areaStyle: {
              // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(0, 252, 150, 0.5)' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(0, 252, 150, 0)' // 100% 处的颜色
                }
              ]) // 背景渐变色
            }
          }
        ]
      }
    };
  },
  computed: {
    chatData() {
      return this.data.map((item) => [item.name, item.count]);
    },
    chatOptions() {
      const self = this;
      return {
        ...self.defaultOptions,
        series: [{ ...self.defaultOptions.series[0], data: self.chatData }]
      };
    }
  },
  methods: {
    initChat() {
      this.chatInstance = this.$echarts.init(this.$refs.chat);
      this.chatInstance.setOption(this.chatOptions);
    }
  },
  mounted() {
    this.initChat();
  }
};
</script>
